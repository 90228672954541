var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { plainToInstance, Transform, Type } from 'class-transformer';
import { IsBoolean, IsDateString, IsEnum, IsNumber, IsOptional, IsString, Validate, ValidateNested, } from 'class-validator';
import { SortingQueryDto } from '../../../classes/common';
import { IsReservationStateQuery } from '../../../classes/reservation';
import { TransformBoolean } from '../../../decorators';
import { VehicleModelType } from '../../../enums';
import { OmitType } from '../../../utils';
export class GetReservationStatisticQueryDto {
}
__decorate([
    IsDateString(),
    __metadata("design:type", String)
], GetReservationStatisticQueryDto.prototype, "startDate", void 0);
__decorate([
    IsDateString(),
    __metadata("design:type", String)
], GetReservationStatisticQueryDto.prototype, "endDate", void 0);
__decorate([
    IsString(),
    __metadata("design:type", String)
], GetReservationStatisticQueryDto.prototype, "criterion", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetReservationStatisticQueryDto.prototype, "branchId", void 0);
__decorate([
    Validate(IsReservationStateQuery),
    IsOptional(),
    __metadata("design:type", Object)
], GetReservationStatisticQueryDto.prototype, "state", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetReservationStatisticQueryDto.prototype, "vehicleModelId", void 0);
__decorate([
    IsEnum(VehicleModelType),
    IsOptional(),
    __metadata("design:type", String)
], GetReservationStatisticQueryDto.prototype, "vehicleModelType", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetReservationStatisticQueryDto.prototype, "isWanted", void 0);
__decorate([
    Transform(({ value }) => plainToInstance(SortingQueryDto, JSON.parse(value))),
    ValidateNested({ each: true }),
    Type(() => SortingQueryDto),
    IsOptional(),
    __metadata("design:type", SortingQueryDto)
], GetReservationStatisticQueryDto.prototype, "sortingQuery", void 0);
export class GetReservationStatisticPerMonthQueryDto extends OmitType(GetReservationStatisticQueryDto, ['startDate', 'endDate']) {
}
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], GetReservationStatisticPerMonthQueryDto.prototype, "year", void 0);
__decorate([
    IsNumber(),
    __metadata("design:type", Number)
], GetReservationStatisticPerMonthQueryDto.prototype, "month", void 0);
export class GetReservationStatisticByVehicleModelQueryDto extends OmitType(GetReservationStatisticQueryDto, ['vehicleModelId']) {
}
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetReservationStatisticByVehicleModelQueryDto.prototype, "isWanted", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetReservationStatisticByVehicleModelQueryDto.prototype, "isReserved", void 0);
export class GetReservationStatisticByVehicleModelPerBranchQueryDto extends OmitType(GetReservationStatisticQueryDto, ['criterion', 'branchId', 'vehicleModelType']) {
}
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetReservationStatisticByVehicleModelPerBranchQueryDto.prototype, "isWanted", void 0);
export const ReservationStatisticByVehicleModelAvailableSortingFields = [
    // dto key
    'vehicleModelId',
    'vehicleModelName',
    'vehicleModelState',
    'vehicleModelType',
    'vehicleModelGraphOrder',
    'reservationCount',
    'vehicleCount',
    'totalUsageTime',
    'averageUsageTime',
    'totalFee',
    'averageFee',
    'averageVehicleFeePerReservation',
    'averageVehicleFeePerVehicle',
    'averageFeePerVehicle',
    'vehicleFee',
    'insuranceFee',
    'extensionFee',
    'extraItemFee',
    'convenienceFee',
    'totalPayment',
    'totalRefund',
    'customerUnpaid',
    'vendorUnpaid',
    // 추가로 정렬할 필드
    'extraFee',
];
