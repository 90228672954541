var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsEnum } from 'class-validator';
import { BaseNumberIdDataDto } from '../../../classes/common';
import { Gender } from '../../../enums';
export class D2CUserForErpDto extends BaseNumberIdDataDto {
    constructor(initializer) {
        super(initializer);
        this.username = initializer.username;
        this.name = initializer.name;
        this.profileImageURL = initializer.profileImageURL;
        this.phoneNumber = initializer.phoneNumber;
        this.email = initializer.email;
        this.gender = initializer.gender;
        this.signUpSource = initializer.signUpSource;
        this.isMarketingAgreed = initializer.isMarketingAgreed;
    }
}
__decorate([
    IsEnum(Gender),
    __metadata("design:type", Object)
], D2CUserForErpDto.prototype, "gender", void 0);
