import { Box, Button, Checkbox, Dialog, Typography } from '@mui/material';
import {
  calculateExtraItemFeeForReservation,
  ExtraItemCategory,
  getRentalDays,
} from '@orcar/common';
import { useIsMutating } from '@tanstack/react-query';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import theme from '@/app/theme';
import BottomButtons from '@/components/BottomButtons';
import LoadingDialog from '@/components/LoadingDialog';
import TitleMessage from '@/components/TitleMessage';
import { useGetExtraItems } from '@/hooks/extra-item.hook';
import {
  useGetReservation,
  useUpdateReservation,
} from '@/hooks/reservation.hook';
import Receipt from '../reservationInfo/components/Receipt';

const SpecialContractPage = () => {
  const id = useParams().id;
  const navigate = useNavigate();

  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState('');

  const { data, isLoading } = useGetReservation({ id });
  const { mutate: updateReservation } = useUpdateReservation();
  const isMutating =
    useIsMutating({
      mutationKey: ['update-reservation'],
    }) > 0;
  const { data: extraItemData, isLoading: isExtraItemLoading } =
    useGetExtraItems({
      params: {
        reservationPeriod: getRentalDays(data?.pickUpAt, data?.dropOffAt),
        vehicleModelId: data?.vehicleModelId,
        insuranceOption: data?.insuranceOption || undefined,
      },
    });

  if (isLoading || isExtraItemLoading) {
    return <></>;
  }

  if (!data || !id) {
    return <Navigate to='/' replace />;
  }

  const specialContracts = extraItemData?.data.filter(
    (item) =>
      item.category === ExtraItemCategory.SPECIAL_CONTRACT &&
      (item.vehicleModels.find((model) => model.id === data.vehicleModelId) ||
        item.global),
  );

  if (
    !specialContracts ||
    specialContracts?.length === 0 ||
    data.extraItems.find(
      (item) => item.extraItem.category === ExtraItemCategory.SPECIAL_CONTRACT,
    )
  ) {
    return <Navigate to={`/${id}/extra-item`} replace />;
  }

  const handleError = (errorMessage: string) => {
    setErrorDialogMessage(errorMessage);
    setErrorDialogOpen(true);
  };
  const selectedItem = specialContracts.find((item) => item.id === selectedId);

  const handleSelectItem = (id: number) => {
    if (selectedId === id) {
      setSelectedId(null);
    } else {
      setSelectedId(id);
    }
  };

  const handleClickNext = () => {
    if (selectedId === null || !selectedItem) {
      navigate(`/${id}/extra-item`);
    } else {
      const fee = calculateExtraItemFeeForReservation(selectedItem, data);
      updateReservation(
        {
          id,
          input: {
            extraItems: [
              ...data.extraItems
                .filter(
                  (item) =>
                    item.extraItem.category !==
                    ExtraItemCategory.SPECIAL_CONTRACT,
                )
                .map((item) => {
                  return {
                    id: item.id,
                  };
                }),
              {
                extraItemId: selectedId,
                quantity: 1,
                fee,
                directPaymentPrice: fee,
              },
            ],
          },
        },
        {
          onSuccess: () => {
            navigate(`/${id}/extra-item`);
          },
          onError: () => {
            handleError('특약 가입에 실패했습니다. 다시 시도해주세요');
          },
        },
      );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        paddingBottom: '240px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          flexGrow: 1,
          background: '#f5f5f5',
          paddingTop: '100px',
          paddingX: '80px',
        }}
      >
        <TitleMessage message='자차특약 추가하기' />
        <Box
          sx={{
            marginTop: '56px',
            width: '100%',
            background: '#ececec',
            border: '1px solid #d4d4d4',
            padding: '36px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            fontSize={32}
            lineHeight={1.5}
            sx={{ wordBreak: 'keep-all', color: '#7e7e7e' }}
          >
            자차면책에서는 보장되지 않는{' '}
            <b>
              자동차키 분실, 차량잠금해제, 배터리방전, 타이어/휠 파손, 견인비용{' '}
            </b>
            등에 대해 저렴한 비용으로 보장하는 상품입니다. <b>(중복선택불가)</b>
          </Typography>
        </Box>
        <Box sx={{ height: '32px' }} />
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll',
            paddingBottom: '32px',
          }}
        >
          {specialContracts.map((item) => (
            <Box
              key={item.id}
              sx={{
                marginTop: 2,
                padding: '32px',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                border: '1px solid',
                borderColor:
                  selectedId === item.id
                    ? 'primary.main'
                    : theme.palette.grey[200],
                background:
                  selectedId === item.id
                    ? 'rgba(51, 202, 119, 0.08)'
                    : '#ffffff',
              }}
              onClick={() => handleSelectItem(item.id)}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 42,
                      },
                    }}
                    checked={selectedId === item.id}
                  />
                </Box>
                <Typography
                  fontWeight='bold'
                  fontSize={32}
                  textAlign='left'
                  color={
                    selectedId === item.id ? 'primary.main' : 'text.primary'
                  }
                >
                  {item.name}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography
                  fontWeight='bold'
                  fontSize={32}
                  textAlign='left'
                  color={
                    selectedId === item.id ? 'primary.main' : 'text.primary'
                  }
                >
                  {calculateExtraItemFeeForReservation(
                    item,
                    data,
                  ).toLocaleString()}
                  원
                </Typography>
              </Box>
              <Typography
                fontSize={28}
                color={selectedId === item.id ? 'primary.main' : 'grey'}
                sx={{ marginTop: '16px' }}
              >
                {item.detail}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Receipt
        reservation={data}
        possibleExtraFee={
          selectedItem &&
          calculateExtraItemFeeForReservation(selectedItem, data)
        }
      />
      <BottomButtons
        label='다음'
        homeButton
        backButton
        onClick={handleClickNext}
        disabled={isMutating}
      />
      <Dialog open={errorDialogOpen}>
        <Box sx={{ width: '100%' }}>
          <Typography
            fontSize={32}
            fontWeight='regular'
            color='text.secondary'
            lineHeight='44px'
            sx={{ marginBottom: 5 }}
          >
            {errorDialogMessage}
          </Typography>
          <Button
            variant='contained'
            fullWidth
            sx={{ height: 100, fontSize: 40, fontWeight: 500 }}
            onClick={() => {
              setErrorDialogOpen(false);
            }}
          >
            닫기
          </Button>
        </Box>
      </Dialog>
      <LoadingDialog open={isMutating} content='잠시만 기다려주세요.' />
    </Box>
  );
};

export default SpecialContractPage;
