export var D2CErrorCode;
(function (D2CErrorCode) {
    // 400
    D2CErrorCode["EXCEED_MAX_RESERVATION_DURATION"] = "EXCEED_MAX_RESERVATION_DURATION";
    D2CErrorCode["MIN_LEAD_TIME_NOT_MET"] = "MIN_LEAD_TIME_NOT_MET";
    D2CErrorCode["EXCEEDED_PICK_UP_TIME_DEADLINE"] = "EXCEEDED_PICK_UP_TIME_DEADLINE";
    D2CErrorCode["UNAVAILABLE_OPERATION_TIME"] = "UNAVAILABLE_OPERATION_TIME";
    D2CErrorCode["PASSWORD_NOT_STRONG_ENOUGH"] = "PASSWORD_NOT_STRONG_ENOUGH";
    D2CErrorCode["PASSWORD_NOT_CORRECT"] = "PASSWORD_NOT_CORRECT";
    D2CErrorCode["USER_NOT_FOUND"] = "USER_NOT_FOUND";
    D2CErrorCode["USER_ALREADY_EXISTS"] = "USER_ALREADY_EXISTS";
    D2CErrorCode["PHONE_NUMBER_ALREADY_EXISTS"] = "PHONE_NUMBER_ALREADY_EXISTS";
    D2CErrorCode["DISCOUNT_CODE_ALREADY_EXISTS"] = "DISCOUNT_CODE_ALREADY_EXISTS";
    D2CErrorCode["DISCOUNT_CODE_NOT_FOUND"] = "DISCOUNT_CODE_NOT_FOUND";
    D2CErrorCode["DISCOUNT_CODE_NOT_VALID"] = "DISCOUNT_CODE_NOT_VALID";
    D2CErrorCode["DISCOUNT_CODE_MAX_AMOUNT_EXCEEDED"] = "DISCOUNT_CODE_MAX_AMOUNT_EXCEEDED";
    D2CErrorCode["DISCOUNT_CODE_MIN_AMOUNT_NOT_MET"] = "DISCOUNT_CODE_MIN_AMOUNT_NOT_MET";
    D2CErrorCode["DISCOUNT_CODE_INVALID_TYPE"] = "DISCOUNT_CODE_INVALID_TYPE";
    D2CErrorCode["DISCOUNT_CODE_INVALID_PERCENT_RANGE"] = "DISCOUNT_CODE_INVALID_PERCENT_RANGE";
    // 412
    D2CErrorCode["SOCIAL_LOGIN_CONFIG_NOT_FOUND"] = "SOCIAL_LOGIN_CONFIG_NOT_FOUND";
})(D2CErrorCode || (D2CErrorCode = {}));
export var ErpErrorCode;
(function (ErpErrorCode) {
    // 400
    ErpErrorCode["DISCOUNT_CODE_ALREADY_EXISTS"] = "DISCOUNT_CODE_ALREADY_EXISTS";
})(ErpErrorCode || (ErpErrorCode = {}));
