var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsBoolean, IsNumber, IsOptional, IsString } from 'class-validator';
import { GetManyQueryDto } from '../../../classes/common/request/get-many-query.dto';
import { TransformBoolean } from '../../../decorators';
import { PartialType } from '../../../utils';
export class GetManyVehicleMaintenancesQueryDto extends PartialType(GetManyQueryDto) {
}
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyVehicleMaintenancesQueryDto.prototype, "fromIncomingDate", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyVehicleMaintenancesQueryDto.prototype, "toIncomingDate", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyVehicleMaintenancesQueryDto.prototype, "maintenanceType", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyVehicleMaintenancesQueryDto.prototype, "vehicleBranchId", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyVehicleMaintenancesQueryDto.prototype, "vehicleModelId", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyVehicleMaintenancesQueryDto.prototype, "serviceCenter", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyVehicleMaintenancesQueryDto.prototype, "partSupplier", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyVehicleMaintenancesQueryDto.prototype, "vehicleNumberKeyword", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    __metadata("design:type", Number)
], GetManyVehicleMaintenancesQueryDto.prototype, "vehicleMileageAfterMaintenance", void 0);
__decorate([
    IsString(),
    IsOptional(),
    __metadata("design:type", String)
], GetManyVehicleMaintenancesQueryDto.prototype, "contentKeyword", void 0);
__decorate([
    TransformBoolean(),
    IsBoolean(),
    IsOptional(),
    __metadata("design:type", Boolean)
], GetManyVehicleMaintenancesQueryDto.prototype, "isRemoveDuplicateType", void 0);
