import {
  type AxiosException,
  type GetReservationInsuranceFeeQueryDto,
  type ReservationDto,
  type UpdateApiPropsWithStringId,
  type UpdateReservationDto,
  type ValidateReservationDriverLicenseAndUpdateDto,
} from '@orcar/common';
import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import dayjs from 'dayjs';
import {
  getReservation,
  getReservationInsuranceFee,
  pickUpReservation,
  updateReservation,
  uploadDriverLicenseImageAndUpdateUrl,
  uploadSignatureImageAndUpdateUrl,
  validateAndUpdateDriverLicense,
} from '@/apis/reservation.api';

export const useGetReservation = ({
  id,
  ...options
}: {
  id?: string;
} & Omit<
  UseQueryOptions<ReservationDto | null, AxiosError<AxiosException>>,
  'queryKey'
>) => {
  return useQuery({
    queryKey: ['reservation', id],
    queryFn: () => (id ? getReservation(id) : null),
    ...options,
  });
};

export const useGetReservationInsuranceFee = ({
  params,
}: {
  params: GetReservationInsuranceFeeQueryDto;
}) => {
  const enabled =
    params.insuranceId > 0 &&
    params.vendorId > 0 &&
    dayjs(params.pickUpAt).isBefore(dayjs(params.dropOffAt));

  return useQuery({
    queryKey: ['reservationInsuranceFee', params],
    queryFn: () => getReservationInsuranceFee(params),
    enabled,
  });
};

export const useUpdateReservation = () => {
  const queryClient = useQueryClient();
  return useMutation<
    ReservationDto,
    AxiosError<AxiosException>,
    UpdateApiPropsWithStringId<UpdateReservationDto>
  >({
    mutationKey: ['update-reservation'],
    mutationFn: updateReservation,
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries({
        queryKey: ['reservation', id],
      });
      queryClient.invalidateQueries({
        queryKey: ['reservationList'],
      });
    },
  });
};

export const useUploadDriverLicenseImageAndUpdateUrl = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ReservationDto,
    AxiosError<AxiosException>,
    UpdateApiPropsWithStringId<{ imageBlob: Blob; driverNumber: 1 | 2 }>
  >({
    mutationFn: uploadDriverLicenseImageAndUpdateUrl,
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries({
        queryKey: ['reservation', id],
      });
      queryClient.invalidateQueries({
        queryKey: ['reservationList'],
      });
    },
  });
};

export const useValidateAndUpdateDriverLicense = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ReservationDto,
    AxiosError<AxiosException>,
    UpdateApiPropsWithStringId<{
      driverNumber: 1 | 2;
      input: ValidateReservationDriverLicenseAndUpdateDto;
    }>
  >({
    mutationFn: validateAndUpdateDriverLicense,
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries({
        queryKey: ['reservation', id],
      });
      queryClient.invalidateQueries({
        queryKey: ['reservationList'],
      });
    },
  });
};

export const usePickUpReservation = () => {
  return useMutation<
    ReservationDto,
    AxiosError<AxiosException>,
    UpdateApiPropsWithStringId<UpdateReservationDto>
  >({ mutationFn: pickUpReservation });
};

export const useUploadSignatureImageAndUpdateUrl = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ReservationDto,
    AxiosError<AxiosException>,
    UpdateApiPropsWithStringId<{
      imageBlob: Blob;
      driverNumber: '1' | '2' | 'privacy';
    }>
  >({
    mutationFn: uploadSignatureImageAndUpdateUrl,
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries({
        queryKey: ['reservation', id],
      });
      queryClient.invalidateQueries({
        queryKey: ['reservationList'],
      });
    },
  });
};
