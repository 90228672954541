export var VehicleModelFeeCalculationMethod;
(function (VehicleModelFeeCalculationMethod) {
    VehicleModelFeeCalculationMethod["HOUR_0"] = "HOUR_0";
    VehicleModelFeeCalculationMethod["HOUR_1"] = "HOUR_1";
    VehicleModelFeeCalculationMethod["HOUR_2"] = "HOUR_2";
    VehicleModelFeeCalculationMethod["HOUR_3"] = "HOUR_3";
    VehicleModelFeeCalculationMethod["HOUR_4"] = "HOUR_4";
    VehicleModelFeeCalculationMethod["HOUR_5"] = "HOUR_5";
    VehicleModelFeeCalculationMethod["N_HOUR_1_0"] = "N_HOUR_1_0";
    VehicleModelFeeCalculationMethod["N_HOUR_2_0"] = "N_HOUR_2_0";
    VehicleModelFeeCalculationMethod["N_HOUR_2_1"] = "N_HOUR_2_1";
    VehicleModelFeeCalculationMethod["N_HOUR_3_0"] = "N_HOUR_3_0";
    VehicleModelFeeCalculationMethod["N_HOUR_3_1"] = "N_HOUR_3_1";
    VehicleModelFeeCalculationMethod["N_HOUR_4_0"] = "N_HOUR_4_0";
    VehicleModelFeeCalculationMethod["N_HOUR_4_1"] = "N_HOUR_4_1";
    VehicleModelFeeCalculationMethod["SIMPLE_1"] = "SIMPLE_1";
    VehicleModelFeeCalculationMethod["SIMPLE_2"] = "SIMPLE_2";
})(VehicleModelFeeCalculationMethod || (VehicleModelFeeCalculationMethod = {}));
export const FEE_CALCULATION_XY_VALUES = {
    [VehicleModelFeeCalculationMethod.HOUR_0]: {
        x: 0,
        y: 0,
        isSimple: false,
    },
    [VehicleModelFeeCalculationMethod.HOUR_1]: {
        x: 1,
        y: 1,
        isSimple: false,
    },
    [VehicleModelFeeCalculationMethod.HOUR_2]: {
        x: 2,
        y: 2,
        isSimple: false,
    },
    [VehicleModelFeeCalculationMethod.HOUR_3]: {
        x: 3,
        y: 3,
        isSimple: false,
    },
    [VehicleModelFeeCalculationMethod.HOUR_4]: {
        x: 4,
        y: 4,
        isSimple: false,
    },
    [VehicleModelFeeCalculationMethod.HOUR_5]: {
        x: 5,
        y: 5,
        isSimple: false,
    },
    [VehicleModelFeeCalculationMethod.N_HOUR_1_0]: {
        x: 1,
        y: 0,
        isSimple: false,
    },
    [VehicleModelFeeCalculationMethod.N_HOUR_2_0]: {
        x: 2,
        y: 0,
        isSimple: false,
    },
    [VehicleModelFeeCalculationMethod.N_HOUR_2_1]: {
        x: 2,
        y: 1,
        isSimple: false,
    },
    [VehicleModelFeeCalculationMethod.N_HOUR_3_0]: {
        x: 3,
        y: 0,
        isSimple: false,
    },
    [VehicleModelFeeCalculationMethod.N_HOUR_3_1]: {
        x: 3,
        y: 1,
        isSimple: false,
    },
    [VehicleModelFeeCalculationMethod.N_HOUR_4_0]: {
        x: 4,
        y: 0,
        isSimple: false,
    },
    [VehicleModelFeeCalculationMethod.N_HOUR_4_1]: {
        x: 4,
        y: 1,
        isSimple: false,
    },
    [VehicleModelFeeCalculationMethod.SIMPLE_1]: {
        x: 1,
        y: 1,
        isSimple: true,
    },
    [VehicleModelFeeCalculationMethod.SIMPLE_2]: {
        x: 2,
        y: 2,
        isSimple: true,
    },
};
